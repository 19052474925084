import scrollIntoView from '../scroll-into-view';
import rot13 from './rot13';
import { usesChromeAutofill } from '../utils/detect';
import { FormApi } from 'final-form';

export const getUniqueName = (name, existingNames) => {
  let count = 0;
  while (existingNames.includes(name)) {
    count++;
    name = `${name}${count}`;
  }
  return name;
};

export function disableAutoCompleteInput(props, disabledAutoComplete) {
  return {
    ...props,
    name: disabledAutoComplete ? rot13(props.name) : props.name,
    autoComplete: disabledAutoComplete
      ? usesChromeAutofill()
        ? Math.random().toString(36).substring(2, 12)
        : 'off'
      : props.autoComplete,
  };
}

const defaultOnError = (errors) => null;

const firstErrorKey = (errors) => {
  const [key, value] = Object.entries<any>(errors || {})[0] || [];

  if (typeof value === 'object' && !value.$$typeof) {
    return key + '.' + firstErrorKey(value);
  }

  return key;
};

const firstErrorKeyV2 = (errors) => {
  const key = firstErrorKey(errors);
  return key
    ?.replaceAll(/\.(\d+)\./g, '[$1].')
    ?.replaceAll(/\.(\d+)/g, '[$1]')
    ?.trim('.');
};

export const getScrollToErrors = (onError = defaultOnError) => {
  const scrollToErrors = (form) => {
    // Save original submit function
    const originalSubmit = form.submit;

    // Subscribe to errors, and keep a local copy of them
    let state: any = {};
    const unsubscribe = form.subscribe(
      (nextState) => {
        state = nextState;
      },
      { errors: true, submitErrors: true }
    );

    const afterSubmit = () => {
      const { errors, submitErrors } = state;

      const fieldNamePrefix = firstErrorKey(errors);
      const fieldNamePrefixV2 = firstErrorKeyV2(errors);

      const selector = `[name^='${fieldNamePrefix}'], [name^='${rot13(fieldNamePrefix)}'], [name^='${fieldNamePrefixV2}'], [name^='${rot13(
        fieldNamePrefixV2
      )}']`;

      console.log({ selector });

      const firstErrorElement = document.querySelector(selector);

      if (!firstErrorElement) {
        return;
      }

      if (!onError(errors)) {
        scrollIntoView(firstErrorElement).then(() => {
          firstErrorElement?.['focus']?.();
        });
      }
    };

    // Rewrite submit function
    form.submit = () => {
      const result = originalSubmit.call(form);
      if (result && typeof result.then === 'function') {
        // async
        result.then(afterSubmit);
      } else {
        // sync
        afterSubmit();
      }
      return result;
    };

    return () => {
      unsubscribe();
      form.submit = originalSubmit;
    };
  };

  return scrollToErrors;
};

export function touchErrors(
  form: FormApi<any, Partial<any>>,
  errors_ = null,
  prefix = ''
) {
  setTimeout(() => {
    const errors = errors_ || form.getState().errors;
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        let field;

        if (Array.isArray(errors)) {
          field = `${prefix}[${key}]`;
        } else {
          field = prefix ? `${prefix}.${key}` : key;
        }

        if (typeof value === 'string') {
          form.mutators.setFieldTouched(field, true);
        } else {
          touchErrors(form, errors[key], field);
        }
      });
    }
  });
}
